<template>
  <div class="mobile-sidebar" :class="{ 'mobile-sidebar-collapsed': !show }">
    <transition name="fade">
      <div v-if="show" class="mobile-sidebar-cover" @click="hideMenu" />
    </transition>
    <div class="mobile-sidebar-drawer">
      <router-link class="home-link" :to="{ name: homeRoute }">
        <LogoFullWhite v-if="colorScheme.isDarkTheme" class="logo" />
        <LogoFull v-else class="logo" />
      </router-link>

      <div
        class="absolute pa-1 cursor-pointer top-6 right-6 text-secondary hover:text-brand"
        @click="hideMenu"
      >
        <SvgIcon icon="Close" />
      </div>

      <div class="overflow-y-auto overflow-x-hidden flex-grow">
        <SideMenu class="min-h-full" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useColorScheme } from '@/composables/color_scheme'
import { useEventBus } from '@/composables/event_bus'
import { useUserStore } from '@/stores/user'
import LogoFull from '@/assets/images/LogoFull.svg?component'
import LogoFullWhite from '@/assets/images/LogoFullWhite.svg?component'
import SvgIcon from '@/components/misc/SvgIcon'
import SideMenu from '../SideMenu/Index.vue'

const eventBus = useEventBus()
const colorScheme = useColorScheme()

const show = ref(false)

const userStore = useUserStore()
const homeRoute = computed(() => userStore.homeRoute)

function showMenu() {
  show.value = true
}

function hideMenu() {
  show.value = false
}

onMounted(() => {
  eventBus.on('showMobileSidebar', showMenu)
  eventBus.on('hideMobileSidebar', hideMenu)
})

onBeforeUnmount(() => {
  eventBus.off('showMobileSidebar', showMenu)
  eventBus.off('hideMobileSidebar', hideMenu)
})
</script>
