<template>
  <Modal :show="showedModal" hide-buttons hide-close>
    <div class="-mt-4 mb-4 mx-8 text-center">
      <span
        class="inline-block rounded border mb-4"
        :class="[
          modalIcon == null ? 'p-2' : 'p-3',
          theme === 'dark' ? 'border-outline' : 'border-brand-light',
        ]"
        :style="modalIcon == null ? 'background-color: #d8e5ff' : undefined"
      >
        <SvgIcon
          v-if="modalIcon"
          :icon="modalIcon"
          class="text-brand"
          size="lg"
        />
        <LogoIcon v-else class="h-8 w-8" />
      </span>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="font-semibold text-xl mb-8" v-html="modalTitle" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="text-base text-secondary" v-html="modalText" />
      <div class="flex justify-center mt-8">
        <Button @click="nextStep">
          <template v-if="actionText">{{ actionText }}</template>
          <template v-else>{{ $t('ui.common.okay') }}</template>
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import tippy from 'tippy.js'
import { defaultProps } from '@/constants/vue-tippy'

import LogoIcon from '@/assets/images/LogoIcon.svg?component'
import Button from '@/components/controls/Button'
import Modal from '@/components/misc/Modal'
import SvgIcon from '@/components/misc/SvgIcon'

import type { PropType } from 'vue'
import type { Instance, Props as TippyProps } from 'tippy.js'
import type { Step } from '@/services/tour.service'

export interface TooltipData {
  showedModal: boolean
  modalIcon: string | null
  modalTitle: string
  modalText: string
  activeStep: number
  tooltipInstance: Instance | null
  actionText: string
}

const defaultTippyOption: Partial<TippyProps> = {
  ...defaultProps,
  trigger: 'manual',
  showOnCreate: true,
  arrow: true,
  maxWidth: 250,
  hideOnClick: false,
}

export default defineComponent({
  components: {
    LogoIcon,
    Button,
    Modal,
    SvgIcon,
  },

  props: {
    steps: {
      type: Array as PropType<Step[]>,
      required: true,
    },
    startStep: {
      type: Number,
      default: 0,
    },
    theme: {
      type: String as PropType<'light' | 'dark'>,
      default: 'light',
    },
  },

  data(): TooltipData {
    return {
      showedModal: false,
      modalIcon: '',
      modalTitle: '',
      modalText: '',
      activeStep: this.startStep,
      tooltipInstance: null,
      actionText: '',
    }
  },

  computed: {
    currentStep(): Step | null {
      if (this.activeStep !== -1 && this.activeStep < this.steps.length) {
        return this.steps[this.activeStep]
      }
      return null
    },
  },

  created() {
    window.addEventListener('popstate', this.hideOnPopState)
  },

  beforeUnmount() {
    window.removeEventListener('popstate', this.hideOnPopState)
  },

  async mounted() {
    if (this.currentStep) {
      // If the current step is waiting, then display the previous one without wait
      let cStep = this.currentStep
      while (cStep.waitUntil && this.activeStep > 0) {
        this.activeStep--
        cStep = this.steps[this.activeStep]
      }

      const actions: Promise<void>[] = []
      for (let i = 0; i < this.activeStep; i++) {
        const step = this.steps[i]
        if (step.action) {
          actions.push(step.action())
        }
      }
      await Promise.all(actions)
      this.showCurrentStep()
    } else {
      this.nextStep()
    }
  },

  methods: {
    async nextStep(): Promise<void> {
      const { currentStep } = this

      if (currentStep?.action) {
        await currentStep.action()
      }

      this.hideCurrentStep()

      if (this.activeStep + 1 < this.steps.length) {
        this.activeStep++
        this.showCurrentStep()
        this.$emit('step-changed', this.activeStep)
      } else {
        this.$emit('finished', this.activeStep)
      }
    },

    showCurrentStep() {
      const { currentStep } = this
      if (!currentStep) {
        return
      }

      if (currentStep.waitUntil && !currentStep.waitUntil()) {
        setTimeout(this.showCurrentStep, 500)
        return
      }

      if (currentStep.onShow) {
        currentStep.onShow(this)
      }

      this.actionText = currentStep.actionText || ''
      if (currentStep.type == 'modal') {
        this.showModalStep(currentStep)
      } else if (currentStep.type == 'tooltip') {
        this.showTooltipStep(currentStep)
      }
    },

    showModalStep(step: Step) {
      this.modalIcon = step.content.icon || null
      this.modalTitle = step.content.title || ''
      this.modalText = step.content.text
      this.showedModal = true
    },

    showTooltipStep(step: Step) {
      if (!step.selector) {
        return
      }

      const element = document.querySelector(step.selector)
      if (!element) {
        return
      }

      let content = `<p class="text-secondary text-xs">${step.content.text}</p>`
      if (step.content.title) {
        content = `<div class="font-bold mb-2">${step.content.title}</div>${content}`
      }

      if (step.action) {
        content += `<div class="flex justify-end mt-2">
          <button type="button" class="crypkit-button sm primary" id="btn-tour-next">
            ${step.actionText ? step.actionText : this.$t('ui.common.okay')}
          </button>
        </div>`
      }

      this.tooltipInstance = tippy(element, {
        ...defaultTippyOption,
        content: `<div class="text-left break-normal p-2">${content}</div>`,
        placement: step.placement || 'auto',
      }) as Instance

      if (step.action) {
        const btnNext = document.querySelector('#btn-tour-next')
        if (btnNext) {
          btnNext.addEventListener('click', this.nextStep)
        }
      }
    },

    hideCurrentStep() {
      const { currentStep } = this
      if (!currentStep) {
        return
      }

      if (currentStep.type == 'modal') {
        this.hideModal()
      } else if (currentStep.type == 'tooltip') {
        this.hideTooltip()
      }

      if (currentStep.onHide) {
        currentStep.onHide(this)
      }
    },

    hideModal() {
      this.showedModal = false
    },

    hideTooltip() {
      if (this.tooltipInstance) {
        this.tooltipInstance.destroy()
      }
    },

    hideOnPopState() {
      const { currentStep } = this
      if (!currentStep) {
        return
      }

      if (currentStep.type == 'modal') {
        this.hideModal()
      } else if (currentStep.type == 'tooltip') {
        this.hideTooltip()
      }
    },
  },
})
</script>
