import type { AxiosResponse } from 'axios'
import ApiService from '@/services/api.service'
import {
  type AssetNotification,
  type GeneralNotification,
  type GeneralNotificationParams,
  type GeneralNotificationResponse,
  type GeneralNotificationsResponse,
  GeneralNotificationType,
  type ReminderNotification,
  type TaggedUserResponse,
  type TaggedUsersResponse,
} from '@/types/services/general_notifications'
import type { EmptyDataResponse } from '@/types/services/common'

class GeneralNotifications {
  static get(
    entity_id: string
  ): Promise<AxiosResponse<GeneralNotificationsResponse>> {
    return ApiService.post('/api/v1/general_notification/search', {
      filters: [
        {
          column: 'entity_id',
          relations: 'eq',
          value: entity_id,
        },
      ],
    })
  }

  static create(
    params: GeneralNotificationParams
  ): Promise<AxiosResponse<GeneralNotificationResponse>> {
    return ApiService.post('/api/v1/general_notifications', params)
  }

  static update(
    id: string,
    params: GeneralNotificationParams
  ): Promise<AxiosResponse<GeneralNotificationResponse>> {
    return ApiService.put(`/api/v1/general_notifications/${id}`, params)
  }

  static remove(id: string): Promise<AxiosResponse<EmptyDataResponse>> {
    return ApiService.delete(`/api/v1/general_notifications/${id}`)
  }

  static isAssetNotification(
    notification: GeneralNotification
  ): notification is AssetNotification {
    return (
      notification.type === GeneralNotificationType.ASSET_PRICE_CHANGE ||
      notification.type === GeneralNotificationType.ASSET_FDV_CHANGE ||
      notification.type === GeneralNotificationType.ENTITY_BALANCE_CHANGE
    )
  }

  static isReminderNotification(
    notification: GeneralNotification
  ): notification is ReminderNotification {
    return notification.type === GeneralNotificationType.REMINDER
  }

  static getTaggedUsers(
    root_id: string
  ): Promise<AxiosResponse<TaggedUsersResponse>> {
    return ApiService.post('/api/v1/telegram_user_tag/search', {
      filters: [
        {
          column: 'root_id',
          relations: 'eq',
          value: root_id,
        },
      ],
    })
  }

  static createTaggedUser(
    root_id: string,
    tag: string
  ): Promise<AxiosResponse<TaggedUserResponse>> {
    return ApiService.post('/api/v1/general_notifications/telegram_user_tag', {
      root_id,
      tag,
    })
  }
}

export default GeneralNotifications
