import { acceptHMRUpdate, defineStore } from 'pinia'
import { isBefore, parseISO } from 'date-fns'

import { HttpError } from '@/helpers/errors'
import GeneralNotifications from '@/services/notifications/general_notification'
import NotificationsService from '@/services/user/notifications'

import {
  type AssetNotification,
  type AssetNotificationParams,
  type GeneralNotification,
  type GeneralNotificationParams,
  GeneralNotificationType,
  type PortfolioAssetPriceChangeNotification,
  type PortfolioAssetPriceChangeParams,
  type ReminderNotification,
  type ReminderNotificationParams,
  type TaggedUser,
} from '@/types/services/general_notifications'
import type { Notification } from '@/types/services/notifications'

const TAGGED_USERS_CACHE_LIFETIME = 5 * 60 * 1000 // 5 minutes

export interface NotificationsState {
  notifications: Notification[]
  total: number
  generalNotifications: {
    data: GeneralNotification[]
    loading: boolean
  }
  taggedUsers: {
    data: TaggedUser[]
    loading: boolean
    lastUpdated?: Date
  }
}

type WithTaggedUsers<T extends GeneralNotificationParams> = T & {
  data: Omit<T['data'], 'tagged_users'>
  tagged_users: TaggedUser[]
}

export type StoreGeneralNotificationParams =
  WithTaggedUsers<GeneralNotificationParams>
export type StorePortfolioAssetPriceChangeParams =
  WithTaggedUsers<PortfolioAssetPriceChangeParams>
export type StoreAssetNotificationParams =
  WithTaggedUsers<AssetNotificationParams>
export type StoreReminderNotificationParams =
  WithTaggedUsers<ReminderNotificationParams>

function mapTaggedUsersToUUIDArray(
  params: StoreGeneralNotificationParams
): GeneralNotificationParams {
  const { tagged_users, ...rest } = params
  return {
    ...rest,
    data: {
      ...rest.data,
      tagged_users: tagged_users.map((user) => user.id),
    },
  } as GeneralNotificationParams // due to TS limitations
}

export const useNotificationsStore = defineStore('notifications', {
  state(): NotificationsState {
    return {
      notifications: [],
      total: 0,
      generalNotifications: {
        data: [],
        loading: true,
      },
      taggedUsers: {
        data: [],
        loading: true,
      },
    }
  },

  actions: {
    async fetchNotifications({
      userId,
      rootId,
      rootUserId,
      offset,
      limit,
    }: {
      userId: string
      rootId: string
      rootUserId: string
      offset?: number
      limit?: number
    }) {
      try {
        const response = await NotificationsService.getNotifications(
          userId,
          rootId,
          rootUserId,
          offset || 0,
          limit || 10
        )
        const now = new Date()
        const data = response.data.data
        let notifications = data.data.filter((item) => {
          return isBefore(parseISO(item.date), now)
        })
        const filteredCount = data.data.length - notifications.length

        if (offset && offset > 0) {
          notifications = this.notifications.concat(notifications)
        }

        this.$patch({
          notifications,
          total: data.total - filteredCount,
        })
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async setDoneNotification(notificationId: string) {
      try {
        await NotificationsService.setDoneNotification(notificationId)

        const idx = this.notifications.findIndex((n) => n.id == notificationId)
        if (idx != -1) {
          this.notifications.splice(idx, 1)
        }
        this.total -= 1
      } catch (err) {
        throw new HttpError(err)
      }
    },

    setReadNotifications(notificationIds: string[]) {
      return new Promise((resolve, reject) => {
        notificationIds.forEach((notificationId) => {
          NotificationsService.setReadNotification(notificationId)
            .then(() => {
              const notification = this.notifications.find(
                (notification) => notification.id == notificationId
              )
              if (notification) {
                notification.read = true
              }
            })
            .catch((err) => {
              reject(err)
            })
        })
        resolve(true)
      })
    },

    async setDoneAllNotifications({
      userId,
      rootId,
      rootUserId,
    }: {
      userId: string
      rootId: string
      rootUserId: string
    }) {
      try {
        await NotificationsService.setDoneAllNotifications(
          userId,
          rootId,
          rootUserId
        )
        this.$patch({
          notifications: [],
          total: 0,
        })
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async fetchGeneralNotifications(entity_id: string) {
      this.generalNotifications = {
        data: [],
        loading: true,
      }

      try {
        const response = await GeneralNotifications.get(entity_id)
        this.generalNotifications = {
          data: response.data.data.sort((a, b) => {
            return b.created.localeCompare(a.created)
          }),
          loading: false,
        }
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async createGeneralNotification(params: StoreGeneralNotificationParams) {
      try {
        await this.createTaggedUsersIfNotExists(params.tagged_users)
        const response = await GeneralNotifications.create(
          mapTaggedUsersToUUIDArray(params)
        )
        if (response.data.result.success) {
          this.generalNotifications.data.unshift(response.data.data)
        }
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async updateGeneralNotification(
      id: string,
      params: StoreGeneralNotificationParams
    ) {
      try {
        await this.createTaggedUsersIfNotExists(params.tagged_users)
        const response = await GeneralNotifications.update(
          id,
          mapTaggedUsersToUUIDArray(params)
        )
        if (response.data.result.success) {
          const idx = this.generalNotifications.data.findIndex(
            (n) => n.id == id
          )
          if (idx != -1) {
            Object.assign(
              this.generalNotifications.data[idx],
              response.data.data
            )
          }
        }
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async deleteGeneralNotification(id: string) {
      try {
        const response = await GeneralNotifications.remove(id)
        if (response.data.result.success) {
          const idx = this.generalNotifications.data.findIndex(
            (n) => n.id == id
          )
          if (idx != -1) {
            this.generalNotifications.data.splice(idx, 1)
          }
        }
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async createPortfolioNotification(
      params: StorePortfolioAssetPriceChangeParams
    ) {
      await this.createGeneralNotification(params)
    },

    async updatePortfolioNotification(
      id: string,
      params: StorePortfolioAssetPriceChangeParams
    ) {
      await this.updateGeneralNotification(id, params)
    },

    async createAssetNotification(params: StoreAssetNotificationParams) {
      await this.createGeneralNotification(params)
    },

    async updateAssetNotification(
      id: string,
      params: StoreAssetNotificationParams
    ) {
      await this.updateGeneralNotification(id, params)
    },

    async createReminderNotification(params: StoreReminderNotificationParams) {
      await this.createGeneralNotification(params)
    },

    async updateReminderNotification(
      id: string,
      params: StoreReminderNotificationParams
    ) {
      await this.updateGeneralNotification(id, params)
    },

    async fetchTaggedUsers(root_id: string) {
      this.taggedUsers.loading = true

      try {
        const response = await GeneralNotifications.getTaggedUsers(root_id)
        this.taggedUsers = {
          data: response.data.data,
          loading: false,
          lastUpdated: new Date(),
        }
      } catch (err) {
        throw new HttpError(err)
      }
    },

    async fetchTaggedUsersIfNeeded(root_id: string) {
      if (
        this.taggedUsers.loading ||
        !this.taggedUsers.lastUpdated ||
        Date.now() - this.taggedUsers.lastUpdated.getTime() >
          TAGGED_USERS_CACHE_LIFETIME
      ) {
        await this.fetchTaggedUsers(root_id)
      }
    },

    async createTaggedUsersIfNotExists(users: TaggedUser[]) {
      try {
        for (const user of users) {
          if (!this.getTaggedUserById(user.id)) {
            const response = await GeneralNotifications.createTaggedUser(
              user.root_id,
              user.tag
            )
            user.id = response.data.data.id
            this.taggedUsers.data.push(user)
          }
        }
      } catch (err) {
        throw new HttpError(err)
      }
    },
  },

  getters: {
    portfolioNotifications(state): {
      data: PortfolioAssetPriceChangeNotification[]
      loading: boolean
    } {
      return {
        data: state.generalNotifications.data.filter(
          (n) => n.type == GeneralNotificationType.PORTFOLIO_ASSET_PRICE_CHANGE
        ),
        loading: state.generalNotifications.loading,
      }
    },
    assetNotifications(state): {
      data: (AssetNotification | ReminderNotification)[]
      loading: boolean
    } {
      return {
        data: state.generalNotifications.data.filter(
          (n) =>
            GeneralNotifications.isAssetNotification(n) ||
            GeneralNotifications.isReminderNotification(n)
        ) as (AssetNotification | ReminderNotification)[],
        loading: state.generalNotifications.loading,
      }
    },
    assetNotificationsByCurrencyId(state) {
      return (
        currency_id: string
      ): {
        data: (AssetNotification | ReminderNotification)[]
        loading: boolean
      } => {
        return {
          data: state.generalNotifications.data.filter(
            (n) =>
              (GeneralNotifications.isAssetNotification(n) ||
                GeneralNotifications.isReminderNotification(n)) &&
              (n.data.currency_id == currency_id ||
                n.data.ico_id == currency_id)
          ) as (AssetNotification | ReminderNotification)[],
          loading: state.generalNotifications.loading,
        }
      }
    },
    reminderNotifications(state): {
      data: ReminderNotification[]
      loading: boolean
    } {
      return {
        data: state.generalNotifications.data.filter(
          (n) =>
            GeneralNotifications.isReminderNotification(n) &&
            !n.data.currency_id &&
            !n.data.ico_id
        ) as ReminderNotification[],
        loading: state.generalNotifications.loading,
      }
    },
    taggedUsersMap(state): Map<string, TaggedUser> {
      const map = new Map<string, TaggedUser>()
      state.taggedUsers.data.forEach((taggedUser) => {
        map.set(taggedUser.id, taggedUser)
      })
      return map
    },
    getTaggedUserById(state) {
      return (id: string): TaggedUser | null => {
        return (
          state.taggedUsers.data.find((taggedUser) => taggedUser.id == id) ??
          null
        )
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useNotificationsStore, import.meta.hot)
  )
}
