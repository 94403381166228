<template>
  <div
    class="crypkit-main-navbar"
    :class="{
      'is-scrolled': isScrolled || isSmallCurrenciesToggled,
      'bg-surface': isScrolled || isSmallCurrenciesToggled,
    }"
  >
    <div
      class="nav-container"
      :class="{ 'justify-between': $breakpoints.mdAndDown }"
    >
      <div class="block lg:hidden">
        <SvgIcon
          icon="Menu"
          class="cursor-pointer"
          size="lg"
          @click="showMobileSidebar"
        />
      </div>
      <div
        class="logo-container hidden lg:flex"
        :class="{ 'border-r border-outline': showTitle }"
      >
        <div key="big" class="logo-content">
          <router-link :to="{ name: homeRoute }">
            <transition mode="out-in" name="fade">
              <LogoFullWhite
                v-if="colorScheme.isDarkTheme.value"
                class="logo"
              />
              <LogoFull v-else class="logo" />
            </transition>
          </router-link>
        </div>
      </div>
      <div
        class="flex-shrink overflow-hidden pr-4"
        :class="showTitle ? 'flex-grow' : 'text-center'"
      >
        <h1 v-if="showTitle" class="mb-0">
          {{ title }}
        </h1>
        <router-link v-else :to="{ name: homeRoute }">
          <LogoIcon class="h-8 w-8 inline-block md:hidden" />
        </router-link>
      </div>
      <div
        class="flex items-center"
        :class="{ 'flex-grow': $breakpoints.lgAndUp }"
      >
        <Currencies v-if="$breakpoints.mdAndUp" class="mr-auto" />
        <SmallCurrencies
          v-else
          @click="isSmallCurrenciesToggled = !isSmallCurrenciesToggled"
        />
        <template v-if="$breakpoints.lgAndUp">
          <AccountSelection class="w-[218px]" />
          <div class="flex items-center">
            <TogglePrivacyMode class="mx-6" />
            <template v-if="userStore.hasPermission(239)">
              <Notification id="navigation-notifications" class="-ml-3 mr-3" />
            </template>
            <UserMenu menu-from-right />
          </div>
        </template>
      </div>
    </div>
    <div class="w-full flex justify-center sm:justify-end">
      <transition name="expand-currencies">
        <Currencies
          v-if="$breakpoints.smAndDown && isSmallCurrenciesToggled"
          class="mb-2 -mt-2"
        />
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'Navbar',
}
</script>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { throttle } from 'lodash-es'

import { useColorScheme } from '@/composables/color_scheme'
import { useEventBus } from '@/composables/event_bus'
import { useGlobalStore } from '@/stores/global'
import { useUserStore } from '@/stores/user'

import LogoFull from '@/assets/images/LogoFull.svg?component'
import LogoFullWhite from '@/assets/images/LogoFullWhite.svg?component'
import LogoIcon from '@/assets/images/LogoIcon.svg?component'

import SvgIcon from '@/components/misc/SvgIcon'

import Currencies from './Currencies.vue'
import AccountSelection from '@/components/navigation/components/AccountSelection.vue'
import Notification from '@/components/navigation/components/Notifications.vue'
import SmallCurrencies from '@/components/navigation/components/SmallCurrencies.vue'
import TogglePrivacyMode from '@/components/navigation/components/TogglePrivacyMode.vue'
import UserMenu from '@/components/navigation/components/UserMenu.vue'

const eventBus = useEventBus()
const colorScheme = useColorScheme()
const globalStore = useGlobalStore()
const userStore = useUserStore()

const isScrolled = ref(false)
const isSmallCurrenciesToggled = ref(false)
const throttledScrollCheck = throttle(scrollCheck, 100)

const homeRoute = computed(() => userStore.homeRoute)
const isAdminView = computed(() => userStore.currentView === 'admin')
const title = computed(() =>
  isAdminView.value ? globalStore.activeViewName : null
)
const showTitle = computed(() => isAdminView.value && Boolean(title.value))

onMounted(() => {
  window.addEventListener('scroll', throttledScrollCheck)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', throttledScrollCheck)
})

function scrollCheck() {
  isScrolled.value = window.scrollY > 0
}

function showMobileSidebar() {
  if (!globalStore.sidebarToggled) {
    globalStore.toggleSidebar()
  }
  eventBus.emit('showMobileSidebar')
}
</script>
