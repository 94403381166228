<template>
  <div class="sidemenu">
    <div
      class="absolute top-0 left-0 bottom-0 w-1"
      :style="{
        backgroundColor: userStore.settings.additional.profile_icon_color,
      }"
    />

    <div
      v-if="$breakpoints.lgAndUp"
      class="sidebar-toggle"
      @click="globalStore.toggleSidebar()"
    >
      <SvgIcon icon="ArrowLeft" :class="{ collapsed: !sidebarToggled }" />
    </div>

    <transition mode="out-in" name="fade">
      <div v-if="sidebarToggled" class="mb-8">
        <div class="flex justify-between items-center ml-4 mr-3">
          <TogglePrivacyMode class="mx-3" />
          <UserMenu />
          <Notification
            v-if="userStore.hasPermission(239)"
            id="navigation-notifications"
          />
          <span v-else style="width: 42px" />
        </div>
      </div>
      <div v-else class="flex flex-col items-center mb-6">
        <AccountBadge
          :show-arrow="false"
          class="cursor-pointer"
          @click="globalStore.toggleSidebar()"
        />
        <TogglePrivacyMode class="mt-6" />
        <Notification
          v-if="userStore.hasPermission(239)"
          id="navigation-notifications"
          class="mt-4"
        />
      </div>
    </transition>

    <AccountSelection class="mx-4 mb-8" />

    <div
      v-if="!userStore.noPermissionsAdmin && !userStore.noPackages"
      class="flex-grow"
    >
      <template v-if="isAdminView">
        <SidebarLink
          v-for="(link, index) in links"
          :key="'as-' + index"
          :link="link"
          :link-index="index"
        />
      </template>
    </div>

    <div v-if="$breakpoints.mdAndUp" class="mx-4 py-4 sticky bottom-0">
      <transition mode="out-in" name="fade">
        <div v-if="sidebarToggled" key="big">
          <DailyClose large class="py-2 mx-2" />
          <Currencies />
        </div>
        <div v-else key="small" class="flex flex-col items-center">
          <DailyClose class="py-2 -mx-2" />
          <SmallCurrencies @click="globalStore.toggleSidebar()" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useGlobalStore } from '@/stores/global'
import { useUserStore } from '@/stores/user'

import SvgIcon from '@/components/misc/SvgIcon'

import AccountBadge from '../components/AccountBadge.vue'
import Currencies from '../components/Currencies.vue'
import DailyClose from '../components/DailyClose.vue'
import Notification from '../components/Notifications.vue'
import SmallCurrencies from '../components/SmallCurrencies.vue'
import TogglePrivacyMode from '../components/TogglePrivacyMode.vue'
import UserMenu from '../components/UserMenu.vue'

import SidebarLink from './SidebarLink.vue'
import AccountSelection from '../components/AccountSelection.vue'
import type { NavLink, ParentNavLink } from '@/types/components/navigation'
import type { TourFlow } from '@/types/services/user_settings'

const { t } = useI18n()
const globalStore = useGlobalStore()
const userStore = useUserStore()

const sidebarToggled = computed(() => globalStore.sidebarToggled)

const isAdminView = computed(() => {
  return userStore.currentView === 'admin'
})

const links = computed((): ParentNavLink[] => {
  const { activeTourFlow, hasPermission, hasPackage } = userStore
  const links: ParentNavLink[] = [
    {
      routeTo: 'DashboardView',
      icon: 'Dashboard',
      text: t('ui.common.dashboard'),
      children: [],
      visible: hasPermission(2) && hasPackage([2, 3, 5, 37, 43, 47]),
    },
    {
      routeTo: '',
      icon: 'Fund',
      text: t('ui.common.fund'),
      visible: hasPermission(277) && hasPackage([3, 43]),
      children: [
        {
          text: t('ui.common.clients'),
          routeTo: 'FundCrmView',
          visible: hasPermission(43),
        },
        {
          text: t('ui.common.fees_and_referrers'),
          routeTo: 'FundFeesView',
          visible: hasPermission(53),
        },
        {
          text: t('ui.common.series_of_shares'),
          routeTo: 'FundSeriesView',
          visible: hasPermission(61),
        },
      ],
    },
    {
      routeTo: '',
      icon: 'Portfolio',
      text: t('ui.common.assets'),
      visible: hasPermission(3) && hasPackage([2, 3, 5, 37, 43, 47]),
      children: [
        {
          text: t('ui.common.portfolio'),
          routeTo: 'AssetsPortfolioView',
          visible: hasPermission(5),
        },
        {
          text: t('ui.common.asset_sources'),
          routeTo: 'AssetSourcesView',
          visible: hasPermission(19) || hasPermission(7) || hasPermission(13),
        },
      ],
    },
    // {
    //   routeTo: 'LiquidityProvidingView',
    //   icon: 'Pulse',
    //   text: t('ui.common.defi'),
    //   // TODO: Temporary set to permission for Assets.
    //   // Change to permissions for DeFi after permissions refactoring.
    //   visible: hasPermission(3) && hasPackage([2, 3, 5, 37, 43, 47]),
    //   children: [
    //     // {
    //     //   text: t('ui.common.liquidity_providing'),
    //     //   routeTo: 'LiquidityProvidingView',
    //     //   visible: hasPermission(3),
    //     // },
    //   ],
    // },
    {
      routeTo: '',
      icon: 'Accounting',
      text: t('ui.common.accounting'),
      visible: hasPermission(149) && hasPackage([5, 47]),
      children: [
        {
          text: t('ui.common.dashboard'),
          routeTo: 'AccountingDashboard',
          visible: hasPermission(229),
        },
        {
          text: t('ui.common.contacts'),
          routeTo: 'AccountingContacts',
          visible: hasPermission(229),
        },
        {
          text: t('ui.common.purchase_invoices'),
          routeTo: 'AccountingPurchaseInvoices',
          visible: hasPermission(229),
        },
        {
          text: t('ui.common.sales_invoices'),
          routeTo: 'AccountingSalesInvoicesView',
          visible: hasPermission(229),
        },
        {
          text: t('ui.common.reports_and_tools'),
          routeTo: 'AccountingReportView',
          visible: hasPermission(229),
        },
        {
          text: t('ui.common.carts_of_accounts'),
          routeTo: 'AccountingChartOfAccounts',
          visible: hasPermission(229),
        },
        {
          text: t('ui.common.transactions'),
          routeTo: 'AccountingTransactionsView',
          visible: hasPermission(229),
        },
        {
          text: t('ui.common.journal'),
          routeTo: 'AccountingJournalView',
          visible: hasPermission(229),
        },
      ],
    },
    {
      routeTo: 'StatisticsPerformanceView',
      icon: 'Statistics',
      text: t('ui.common.statistics'),
      visible:
        hasPermission(71) &&
        hasPermission(73) &&
        hasPackage([2, 3, 5, 37, 43, 47]),
      children: [],
      // children: [
      //   {
      //     text: t('ui.common.performance'),
      //     routeTo: 'StatisticsPerformanceView',
      //     visible: hasPermission(73),
      //   },
      //   // TEMPORARILY DISABLED
      //   // {
      //   //   text: t('ui.common.portfolio_maker'),
      //   //   routeTo: 'StatisticsPortfolioMakerView',
      //   //   visible: hasPermission(79)
      //   // },
      //   // {
      //   //   text: t('ui.common.rebalance'),
      //   //   routeTo: 'StatisticsRebalanceView',
      //   //   visible: hasPermission(83)
      //   // },
      // ],
    },
    // {
    //   routeTo: '',
    //   icon: 'Trades',
    //   text: t('ui.common.trades'),
    //   visible:
    //     hasPermission(89) && hasPackage([2, 3, 5, 37, 43, 47]),
    //   children: [
    //     {
    //       text: t('ui.common.cex'),
    //       routeTo: 'CexView',
    //       visible: hasPermission(97),
    //     },
    //   ],
    // },
    {
      routeTo: '',
      icon: 'Settings2',
      text: t('ui.common.manage'),
      visible: hasPermission(191),
      children: [
        {
          text: t('ui.common.fund'),
          routeTo: 'SettingsFundsView',
          visible: hasPermission(223) && hasPackage([3, 43]),
        },
        {
          text: t('ui.common.portfolios'),
          routeTo: 'SettingsPortfoliosView',
          // TODO: Add check permissions - hasPermission(XXX) &&
          visible: hasPackage([2, 37]),
        },
        {
          text: t('ui.common.admins'),
          routeTo: 'SettingsAdminsView',
          visible: hasPermission(199) && hasPackage([3, 5, 43, 47]),
        },
        {
          text: t('ui.common.accounting'),
          routeTo: 'AccountingSettingsView',
          visible: hasPermission(229) && hasPackage([5, 47]),
        },
      ],
    },
    {
      routeTo: '',
      icon: 'Services',
      text: t('ui.common.services'),
      visible: hasPermission(331) && hasPackage([2, 3, 5, 37, 43, 47]),
      children: [
        {
          text: t('ui.common.supported_services'),
          routeTo: 'SupportedServicesView',
          visible: hasPermission(283),
        },
        {
          text: t('ui.common.monitoring'),
          routeTo: 'MonitoringView',
          visible: hasPermission(313),
        },
      ],
    },
  ]

  return links
    .filter((link) => link.visible)
    .map((link) => {
      link.children = link.children
        .filter((sublink) => sublink.visible)
        .map((sublink) => ({
          ...sublink,
          disabled: isDisabledByTour(sublink, activeTourFlow),
        }))
      link.disabled = isDisabledByTour(link, activeTourFlow)
      return link
    })
})

function isDisabledByTour(
  link: ParentNavLink | NavLink,
  currentFlow: TourFlow | null
) {
  if (
    !currentFlow ||
    currentFlow.scenario_key !== 'onboarding' ||
    currentFlow.current_step == 7
  ) {
    return false
  }

  if (currentFlow.current_step == 0) {
    return true
  }

  let allowedRouteName = 'AssetSourcesView'
  if (currentFlow.current_step < 3) {
    allowedRouteName = 'SettingsPortfoliosView'
  }

  if (link.routeTo === allowedRouteName) {
    return false
  }
  if ('children' in link && link.children.length) {
    return !link.children.some(
      (sublink) => sublink.routeTo === allowedRouteName
    )
  }

  return true
}
</script>
