<template>
  <div
    class="sidebar flex flex-col"
    :class="{ 'sidebar-mini': !sidebarToggled }"
  >
    <div
      v-if="isSmallScreen && sidebarToggled"
      class="sidebar-body-cover"
      :style="{ width: coverWidth }"
      @click="closeMenu"
    />

    <SideMenu class="pt-8" />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useBreakpoints } from '@/composables/breakpoints'
import { useGlobalStore } from '@/stores/global'

import SideMenu from '../SideMenu/Index.vue'

const breakpoints = useBreakpoints()
const globalStore = useGlobalStore()

const coverWidth = ref('0')

const sidebarToggled = computed(() => globalStore.sidebarToggled)
const isSmallScreen = computed(() => globalStore.isSmallScreen)

function computeCoverWidth() {
  coverWidth.value = `${breakpoints.width}px`
}

function closeMenu() {
  if (sidebarToggled.value && isSmallScreen.value) {
    globalStore.toggleSidebar()
  }
}

onMounted(() => {
  computeCoverWidth()
  window.addEventListener('resize', computeCoverWidth)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', computeCoverWidth)
})
</script>
