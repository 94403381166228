import {
  type AsyncComponentLoader,
  type Component,
  type ComponentPublicInstance,
  defineAsyncComponent,
} from 'vue'
import { Loading, LoadingError } from '@/components/misc/Loading'

/**
 * Returns the release version of the application.
 *
 * @return {string} The release version of the application.
 */
export function releaseVersion(): string {
  return __RELEASE_VER__
}

export function isProdBuild(): boolean {
  return __IS_PROD_BUILD__
}

export const API_URL = {
  v1: import.meta.env.VUE_APP_ROOT_API || '',
  v2: import.meta.env.VUE_APP_ROOT_API_V2 || '',
}

export function downloadFile(uri: string, filename: string) {
  const link = document.createElement('a')
  link.download = filename
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function downloadDataAsCsv(data: any[][], filename: string) {
  const csvContent =
    'data:text/csv;charset=utf-8,\uFEFF' +
    encodeURIComponent(data.map((e) => e.join(';')).join('\n'))

  downloadFile(csvContent, `${filename}.csv`)
}

export function defineLoadingAsyncComponent<
  T extends Component = {
    new (): ComponentPublicInstance
  },
>(source: AsyncComponentLoader<T>): T {
  return defineAsyncComponent<T>({
    loader: source,
    loadingComponent: Loading,
    delay: 200,
    timeout: 5000,
    errorComponent: LoadingError,
  })
}
