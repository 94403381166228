import { inject } from 'vue'
import type { Breakpoints } from '@/types/plugins/breakpoints'

export function useBreakpoints(): Breakpoints {
  const breakpoints = inject<Breakpoints>('breakpoints')
  if (!breakpoints) {
    throw new Error('Breakpoints plugin is not registered')
  }
  return breakpoints
}
